import React, { useEffect, useState } from 'react';
import SearchBar from '../components/SearchBar';
import CategoryFilter from '../components/CategoryFilter';
import LinkCard from '../components/LinkCard';
import LinkList from '../components/LinkList';
import QuickLinks from '../components/QuickLinks';
import { FaTh, FaList, FaBolt } from 'react-icons/fa';

const CACHE_DURATION = 6 * 3600 * 1000; // 12 hour in milliseconds

const categoryColors = {
    AEM: 'bg-orange-800',
    AEMaaCS: 'bg-red-800',
    EDS: 'bg-fuchsia-800',
    Local: 'bg-stone-800',
    Tools: 'bg-emerald-800',
    AI: 'bg-indigo-800'
};

// Utility functions for caching
const getCachedData = () => {
    const cachedData = sessionStorage.getItem('linksData');
    const cacheTimestamp = sessionStorage.getItem('linksDataTimestamp');
    
    if (cachedData && cacheTimestamp) {
        const now = new Date().getTime();
        if (now - parseInt(cacheTimestamp, 10) < CACHE_DURATION) {
            return JSON.parse(cachedData);
        } else {
            sessionStorage.removeItem('linksData');
            sessionStorage.removeItem('linksDataTimestamp');
        }
    }
    return null;
};

const cacheData = (data) => {
    const now = new Date().getTime();
    sessionStorage.setItem('linksData', JSON.stringify(data));
    sessionStorage.setItem('linksDataTimestamp', now);
};

function Home() {
  const [data, setData] = useState(null);
  const [view, setView] = useState('card'); // 'card' or 'list'
  const [searchTerm, setSearchTerm] = useState('');
  const [activeCategories, setActiveCategories] = useState([]);
  const [showQuickLinks, setShowQuickLinks] = useState(false);

  useEffect(() => {
    const cachedData = getCachedData();
    if (cachedData) {
      setData(cachedData);  // Use cached data if available and valid
    } else {
      fetch('/links.json')
        .then(response => response.json())
        .then(json => {
          setData(json);
          cacheData(json);  // Cache the fetched data
        })
        .catch(error => console.error('Error fetching links.json:', error));
    }
  }, []);

  const toggleCategory = (categoryId) => {
    setActiveCategories(prev => 
      prev.includes(categoryId) ? prev.filter(id => id !== categoryId) : [...prev, categoryId]
    );
  };

  const filterLinks = () => {
    if (!data) return [];

    return data.links.filter(link => {
      // Category Filter
      const categoryMatch = activeCategories.length === 0 || activeCategories.includes(link.categoryId);

      // Search Filter
      const searchMatch = searchTerm === '' || 
        link.title.toLowerCase().includes(searchTerm.toLowerCase()) || 
        link.description.toLowerCase().includes(searchTerm.toLowerCase());

      return categoryMatch && searchMatch;
    });
  };

  const filteredLinks = filterLinks();

  if (!data) {
    return <div className="text-center">Loading...</div>;
  }

  const categoriesMap = {};
  data.Categories.forEach(cat => {
    categoriesMap[cat.id] = cat.title;
  });

  return (
    <div className="space-y-6">
      <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
      <CategoryFilter 
        categories={data.Categories} 
        activeCategories={activeCategories} 
        toggleCategory={toggleCategory} 
      />
      
      <div className="flex justify-center space-x-6 mb-6">
        {/* Card View Button */}
        <button
          onClick={() => setView('card')}
          aria-label="Card View"
          className={`lg:flex hidden items-center justify-center p-3 border border-gray-300 rounded md:text-md text-sm ${
            view === 'card' ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-black'
          }`}
        >
          <FaTh className="text-xl" />
        </button>

        {/* List View Button */}
        <button
          onClick={() => setView('list')}
          aria-label="List View"
          className={`lg:flex hidden items-center justify-center p-3 border border-gray-300 rounded md:text-md text-sm ${
            view === 'list' ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-black'
          }`}
        >
          <FaList className="text-xl" />
        </button>

        {/* Quick Links Button */}
        <button
          onClick={() => setShowQuickLinks(!showQuickLinks)}
          aria-label="Quick Links"
          className={`flex items-center justify-center border border-gray-300 space-x-2 px-4 py-2 rounded ${
            showQuickLinks ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-600 hover:bg-gray-200 hover:text-black'
          }`}
        >
          <FaBolt className="text-xl" />
          <span className="text-sm mt-1">{showQuickLinks ? 'Hide Quick Links' : 'Quick Links'}</span>
        </button>
    </div>

    <div className="flex flex-col md:flex-row items-center justify-between mb-6">
        {/* Left Side: Link Count */}
        <div className="md:text-md text-sm font-normal">
          Showing {filteredLinks.length} {filteredLinks.length === 1 ? 'link' : 'links'}
        </div>

        {/* Right Side: Last Update Date */}
        <div className="md:text-md text-sm font-normal">
          Last Updated: {data.last_update_date}
        </div>
      </div>


      {showQuickLinks && <QuickLinks quicklinks={data.quicklinks} />}
      
      <div className={view === 'card' ? 'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-6' : 'space-y-4'}>
        {filteredLinks.map((link, index) => (
          view === 'card' ? (
            <LinkCard key={index} link={link} categoryTitle={categoriesMap[link.categoryId]} categoryColor={categoryColors[link.categoryId]} />
          ) : (
            <LinkList key={index} link={link} categoryTitle={categoriesMap[link.categoryId]} categoryColor={categoryColors[link.categoryId]} />
          )
        ))}
      </div>
    </div>
  );
}

export default Home;
