import React from 'react';

function LinkList({ link, categoryTitle, categoryColor }) {
  return (
    <div className="border-b border-gray-200 py-4 flex flex-col hover:bg-gray-50 hover:pl-2 transition-colors duration-300">
      <div className="flex justify-between items-center">
        <div className="inline-flex">
            <h2 className="text-lg font-semibold">
            <a href={link.url} target="_blank" rel="noopener noreferrer" className="text-gray-700 hover:underline">
                {link.title}
            </a>
            </h2>
            <span className="mx-2">|</span>
            <div className="text-xs text-gray-500 mt-1">
            {link.author && (
            <span>
                By{' '}
                {link.authorLink ? (
                <a href={link.authorLink} target="_blank" rel="noopener noreferrer" className="text-gray-500 hover:underline">
                    {link.author}
                </a>
                ) : (
                <span className="text-gray-500">{link.author}</span>
                )}
            </span>
            )}
            {link.author && link.date && <span className="mx-2">|</span>}
            {link.date && <span>{link.date}</span>}
            </div>
        </div>
        <span className={`text-white text-xs font-semibold px-2 py-1 opacity-80 rounded ${categoryColor}`}>
          {categoryTitle}
        </span>
      </div>
      <p className="text-sm text-gray-700 mt-2">{link.description}</p>
      <div className="mt-2">
        {link.tags.map((tag, index) => (
          <span key={index} className="inline-block bg-gray-200 text-gray-700 text-xs px-2 py-1 rounded-full mr-2">
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
}

export default LinkList;
